import React from 'react';
import parse from "html-react-parser";
import styles from "./faq.module.scss";

function FaqRowItem({index, item, toggleItem, selectedItem}) {
    return (
        <div className={`${styles.accordion__item} ${index === selectedItem ? styles.show : ""}`} id={"main_item_" + index}
             onClick={(e) => toggleItem(e, index)}>
            <div className={`${styles.accordionHeader} accordion-header`}>
                <div className={`${styles.accordionButton} accordion-button`}>
                    <h3 className={styles.faqTitle}>{item.question}</h3>
                </div>
            </div>
            {selectedItem === index && <div className={`${styles.accordionBody}`}
                                            id={"item_" + index}>
                <div className={styles.desc}>
                    {item.answer && parse(item.answer)}
                </div>
            </div>
            }
        </div>
    );
}

export default FaqRowItem;